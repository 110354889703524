import itemSchema from './schemas/ordersync.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from "axios";

const resourceName = 'ordersyncs'
const base = new ModuleBase(itemSchema, resourceName);
export default {
    namespaced: base.namespaced,
    state: {
        ...base.state,
        listStatus: [
            {
                value: '',
                text: 'All status'
            },
            {
                value: 0,
                text: 'New'
            },
            {
                value: 1,
                text: 'Published'
            }
        ],
    },
    getters: {
        ...base.getters,
        listOrderStatus: state => state.listStatus,
    },
    mutations: {
        ...base.mutations,
    },
    actions: {
        ...base.actions,
      // eslint-disable-next-line no-empty-pattern
      async publishOrder({}, payload) {
        return axios.post('orders', payload)
          .then(response => {
            return response
          })
          .catch(err => {
            console.log('Error when create order:', err);
          })
      },
      // eslint-disable-next-line no-empty-pattern
      async batchEdit({}, payload) {
        return axios.post('ordersyncs/batch-update', payload)
          .then(response => {
            return response
          })
          .catch(err => {
            console.log('Error update batch:', err);
          })
      },
    }
}
