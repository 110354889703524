export default {
  dashboard: '仪表盘',
  introduction: 'Introduction',
  documentation: '文档',
  guide: '指引',
  roadmap: '线路图',
  i18n: '国际化',
  theme: '主题',
  // pagePermission: 'Page Permission',
  // rolePermission: 'Role Permission',
  permissions: '权限',
  permission: {
    admin: '管理员',
    editor: '编辑者',
    visitor: '访问者',
  },
  components: '组件',
  component: {
    avatarUpload: '头像上传',
    backToTop: '回到顶部',
    countTo: '累加器',
    dragKanban: '可拖动看板',
    dropzone: 'Dropzone',
    jsonEditor: 'Json编辑器',
    markdown: 'Markdown',
    splitPane: '分隔栏',
    sticky: '黏贴(Sticky)',
    tinymce: '富文本编辑(Tinymce)',
  },
  vuetify: 'Vuetify UI',
  vuetifyComponents: {
    /* There might be some different names of each component in this section,
    so the original English name of each component is reserved. */
    components: '组件/Components',
    alert: '警告/Alert',
    avatar: '头像/Avatars',
    badge: '标徽/Badges',
    buttons: '按钮/Buttons',
    calendar: '日历/Calendar',
    cards: '卡片/Cards',
    carousels: '轮播/Carousels',
    chip: '胶囊/Chip',
    colors: '颜色/Colors',
    datepicker: '日期选择/Datepicker',
    dialogs: '对话框/Dialogs',
    grid: '网格/Grid',
    icon: '图标/Icons',
    pagination: '分页/Pagination',
    parallax: '视差/Parallax',
    progress: '进度条/Progress',
    slider: '滑动/Slider',
    snackbar: '消息提示/Snackbar',
    tables: '表格/Tables',
    timepicker: '时间选择/Timepicker',
    tooltip: '提示信息/Tooltip',
    typography: '版式/Typography',
  },
  errors: '错误',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: '图表',
  chart: {
    keyboardChart: '柱状图表',
    lineChart: '曲线图',
    mixChart: '混合图表',
  },
  nested: {
    nested: '嵌套',
    nested1: '一级',
    nested2: '二级',
    nested3: '三级',
  },
  clipboard: '剪切板',
  externalLink: '外部链接',
  config: 'Config',
};
