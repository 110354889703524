import itemSchema from './schemas/big_container.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from "axios";

const resourceName = 'big_containers'
const base = new ModuleBase(itemSchema, resourceName);
const FileSaver = require('file-saver');

export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
  },
  getters: {
    ...base.getters,
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions,
    // eslint-disable-next-line no-empty-pattern
    changeClearanceStatus: async ({}, payload) => {
      return await axios.post(resourceName + '/change-clearance-status', payload);
    },
    // eslint-disable-next-line no-unused-vars
    async exportItem({commit, rootState}, payload) {
      await axios.post('/' + resourceName + `/export`, payload, {
        responseType: 'blob'
      })
        .then((res) => {
          FileSaver.saveAs(res.data, 'export_big_containers.xlsx')
        })
        .catch(err => {

        })
    },
    async exportManifestItem({commit, rootState}, payload) {
      await axios.post('/' + resourceName + `/export-manifest`, payload, {
        responseType: 'blob'
      })
        .then((res) => {
          FileSaver.saveAs(res.data, 'export_big_containers.xlsx')
        })
        .catch(err => {

        })
    },
  }
}
