import itemSchema from './schemas/service.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import CONSTANTS from '@/config/constants'

const resourceName = 'services'
const base = new ModuleBase(itemSchema, resourceName);
let printTemplateDefault = {};
Object.keys(CONSTANTS.SERVICE_TYPE).forEach(service => {
  printTemplateDefault[CONSTANTS.SERVICE_TYPE[service]] = 'Default'
});

export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    
    listPrintTemplates: [
      {name: 'Default'},
      {name: 'Envelop'},
    ],
    printTemplateDefault: printTemplateDefault
  },
  getters: {
    ...base.getters,
    listPrintTemplates: state => state.listPrintTemplates,
    printTemplateDefault: state => state.printTemplateDefault,
    serviceName: state => service_id => {
      if (state.all.find(({id}) => id === service_id)) {
        return state.all.find(({id}) => id === service_id).name
      } else return ''
    },
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions
  }
}
