import route from '@/admin/locale/en_US/route';
import base from "@/admin/locale/en_US/base";
import menu from "@/admin/locale/en_US/menu";

export default {
  route,
  menu,
  ...base,

};
