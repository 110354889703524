import itemSchema from './schemas/tracking_number_history.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from "axios";

const resourceName = 'tracking_number_histories'
const base = new ModuleBase(itemSchema, resourceName);
export default {
    namespaced: base.namespaced,
    state: {
        ...base.state,
    },
    getters: {
        ...base.getters,
    },
    mutations: {
        ...base.mutations,
    },
    actions: {
        ...base.actions,
        getTrackingNumberHistory: async ({}, trackingNumber) => {
          return await axios.get(resourceName + '/search/' + trackingNumber)
        },
    }
}
