import itemSchema from './schemas/setting.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from "axios";

const resourceName = 'settings'
const base = new ModuleBase(itemSchema, resourceName);
export default {
    namespaced: base.namespaced,
    state: {
        ...base.state,
    },
    getters: {
        ...base.getters,
    },
    mutations: {
        ...base.mutations,
    },
    actions: {
        ...base.actions,
      triggerCommand: async ({}, data) => {
        let res = await axios.post(resourceName + '/trigger-command', data);
        return res.status === 200 && res.data && res.data.data ? res.data.data : null;
      }
    }
}
