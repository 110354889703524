import itemSchema from './schemas/country.schema';
import ModuleBase from "@/store/modules/ModuleBase";

const resourceName = 'countries'
const base = new ModuleBase(itemSchema, resourceName);
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
  },
  getters: {
    ...base.getters,
    getName: state => countryId => {
      let country = state.all.filter(c => c.id === countryId);
      if (country.length) return country[0].name;
      return ''
    },
    getCode: state => countryId => {
      let country = state.all.filter(c => c.id === countryId);
      if (country.length) return country[0].country_code;
      return ''
    },
    getCountryByCode: state => countryCode => {
      let country = state.all.filter(c => c.country_code === countryCode);
      if (country.length) return country[0];
      return {}
    }
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions,
  }
}
